@import "nowui/now-ui-kit/variables";

.page__update-notification-wrap {
  visibility: hidden;
  width: 100%;
  background-color: rgba(44, 44, 44, 0.9);
  color: #fff;
  border-radius: 3px 2px 0 0;
  padding: 16px;
  position: fixed;
  z-index: 100;
  left: 0;
  bottom: 0px;

  .cookie-container {
    max-height: 70vh;
    overflow-y: auto;
    position: relative;
  }

  &.show {
    visibility: visible;
    //@include animation(sw-update-fadein 0.8s forwards);
  }

  a {
    color: $light-gray;
    text-decoration: underline;
    //@include transition(color .15s ease-out);

    &:hover {
      color: $white-color;
    }
  }
  p {
    line-height: 1.2em;
    font-size: 1.1em;
    color: $white-color;
    font-weight: 400;
  }

  img {
    position: absolute;
    width: 64px;
    top: -50px;
    left: 0px;
  }

  .form-check .form-check-sign::after {
    color: $white-color;
  }
}
/*

@include keyframes(sw-update-fadein) {
  from {bottom: -100px}
  to {bottom: 0;}
};

*/

