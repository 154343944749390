.col-sortable {
  cursor: pointer;

  &:hover:before {
    opacity: 1;
  }

  &:before {
    font-family: "Font Awesome 5 Free";
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-weight: 900;
    color: $medium-gray;
    content: "\f0dc";
    opacity: 0;
    width: 18px;
    text-align: center;
  }

  &.col-sortable-asc:before {
    content: "\f15d";
    opacity: 1;
  }
  &.col-sortable-desc:before {
    content: "\f15e";
    opacity: 1;
  }
}
