@import "./nowui/now-ui-kit/variables";
.stats-link {
  display: inline-flex !important;
  gap: 5px;
  flex-wrap: wrap;
}


.img-16x9 {
  width: 100%;
  padding-top: 56.25%;
  background-color: $light-gray;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    font-size: 20px;
    color: white;
    object-fit: cover;
  }
}
