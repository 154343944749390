.landing-page-tips {
  margin-top: 88px;
}

.outside-the-box {
  h2.title {
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .outside-the-box--reasons {
    display: flex !important;
    flex-direction: column;
  }
  .info {
    padding: 0;
    margin: 0;
    display: flex;

    p {
      padding-top: 23px;
      margin: 0;
    }
  }

  .card-background {
    margin-bottom: 0;

    .card-body {
      padding-top: 140px;
      padding-bottom: 140px;
    }
  }
  hr {
    margin: 70px auto;
    max-width: 970px;
  }
}

.landing-page--pjl-desc {
  text-align: center;
  padding: 80px 0;


  @media screen and (max-width: 768px) {
    .info {
      padding-top: 30px;
      padding-bottom: 10px;
    }
  }
}


.card-fashion .icon {

    width: 30px
}
