@mixin logo($borderColor, $labelColor, $labelSize, $size) {
  margin: auto;
  //width: calc( $size + $size/10);
  position: relative;

  .frame {
    height: $size;
    width: $size;
    margin: 10px;

    border-left: none;
    border-top: 3px solid $borderColor;
    border-right: 3px solid $borderColor;
    border-bottom: 3px solid $borderColor;
    box-sizing: border-box;
    color: #000;
    position: relative;
    vertical-align: middle;
  }

  .mask {
    position: absolute;
    top: -3px;
    left: -3px;
    height: 30%;
    width: 3px;
    background-color: $borderColor;
  }
  .label {
    font-size: $labelSize;
    color: $labelColor;
    float: left;
    bottom: $labelSize/3.2;
    position: absolute;
    left: -$labelSize/2.5;
    letter-spacing: $labelSize/4;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
    padding: 0px;
    text-transform: lowercase;
  }
}

.logo-white {
  @include logo(#fff, #fff, 96px, 200px);
}

.logo-white.small {
  @include logo(#fff, #fff, 48px, 100px);
}

.logo-white.xsmall {
  @include logo(#fff, #fff, 16px, 32px);

}

.logo-white.xsmall .label {
  font-size: 14px;
  bottom: 1px;
  left: -1.6px;
}

