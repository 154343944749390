$color-p1-darker: #31465d;
$color-p1-dark: #547da1;
$color-p1-normal-b: #b6c0c5;
$color-p2-darker: #15030A;
$color-p2-dark: #4A182C;
$color-p2-normal-a: #7A3B56;
$color-p4-dark: #888;
$color-facebook: #3b5998;
$color-google: #db4437;

$btn-font-weight: normal;
$padding-base-vertical: 6px;
$padding-base-horizontal: 12px;
$font-size-base: 14px;
$line-height-base: 1.428571429;
$border-radius-base: 4px;
$btn-border-radius-base: $border-radius-base;
$btn-default-color: #333;
$btn-default-bg: #fff;
$btn-default-border: #ccc;
$cursor-disabled: not-allowed;
$offset: 187;
