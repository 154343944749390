.video-container {
  @media (max-width: 768px) {
    width: 100%;
    height: calc(100vw * 0.52625);
  }
}

.panoramic-container {
  padding: calc(56.25% - 188px) 0px 0px;
  position: relative;
  background-color: #2c2c2c;

  @media (max-width: 768px) {
    padding: 0;
  }
}

.views-counter {
  border-width: $border-thick;
  font-weight: $font-weight-normal;
  font-size: $font-size-small;
  line-height: $line-height;
  margin: 0px 1px;
  border: none;
  border-radius: $border-radius-small;
  padding: $padding-btn-vertical $padding-btn-horizontal;
  display: inline-block;

  &:first-child {
    margin-left: 0px;
    padding-left: 0px;
  }
}

.video-header {
  margin-top: 30px;
}
.video-title {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  h1 {
    font-size: 2.5em;
    margin: 0px;
  }

  .btn {
    margin: 0px;
  }
}
