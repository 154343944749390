img {
  max-width: 100%;
  border-radius: 1px;
}
.img-raised {
  box-shadow: $box-shadow-raised;
}
.img-preview {
  background-size: cover;
  background-repeat: no-repeat;
}

.img-preview-header {

  z-index: -2;
  background-size: cover;
  background-repeat: no-repeat;
}
