.cover-image-upload-container {
  position: relative;

  .upload-buttons {
    z-index: 1000;
    position: absolute;
    right: 20px
  }
}

