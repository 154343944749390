.markdown-content__image-block {
  display: block;
  text-align: center;
  padding: 5px 0;

  img {
    max-width: 100%;
  }

  >span {
    display: block;
    font-style: italic;
    font-size: 16px;
    font-weight: 300;
    word-wrap: break-word;
  }
}
