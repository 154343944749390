@import "./mixins.scss";
@import "./variables.scss";

.RML-btn {
  background-color: #2ca8ff;
  color: #fff;
  display: inline-block;
  font-weight: 400;
  border-radius: 30px;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  font-size: 1em;
  transition: all 0.15s ease 0s;

  &:hover {
    box-shadow: 0 3px 8px 0 rgb(0 0 0 / 17%);
  }

  &:disabled {
    opacity: .65;
    pointer-events: none;
  }

  a {
    &.disabled,
    fieldset[disabled] & {
      pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
    }
  }

  &:focus {
    outline: none;
  }
}

.RML-login-modal-wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2010;
  top: 0;

  @include keyframes(login-form-fade-in) {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @include keyframes(fade-in) {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
.RML-login-modal-form {
  label {
    display: none;
  }

  .form-group {
    margin-bottom: 20px;
  }

  .login-modal__bottom-container {
    color: $default-color;
    order: 2;
    text-align: right;
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;

    a {
      color: $info-color;
    }
  }
}
.RML-form-group {
  margin-bottom: 0 !important;

  label {
    //font-size: 14px;
  }
}

.RML-form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  box-sizing: border-box;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

  @include transition(
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s
  );
}

.RML-login-modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2011;
  background-color: $color-p1-darker;
  opacity: 0.6;
  left: 0;
  @media screen and (max-width: 576px) {
    background-color: #fff;
    opacity: 1;
  }
}

.RML-login-modal-box {
  border-radius: $border-radius-small;
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  max-width: calc(100% - 40px);
  max-height: calc(100% - 30px);
  background-color: #2ca8ff;
  border: 1px solid $color-p1-dark;
  z-index: 2012;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;

  @media screen and (max-width: 576px) {
    width: 100vw;
    max-width: 100vw;
    top: 0;
    left: 0;
    transform: none;
    border-radius: 0px;
    max-height: 100vh;
    box-shadow: none;
    border: none;
  }

  .RML-login-modal-form {
    display: flex;
    flex-direction: column;
    opacity: 1;

    button {
      @include transition(background-color 0.5s);
      float: right;
    }

    //@include animation(login-form-fade-in 2s forwards);

  }

  width: 400px;
}
.RML-login-modal-error--login,
.RML-login-modal-error {
  display: inline-block;
  //max-width: calc(~"100% - 75px");
  padding-right: 5px;
  color: $danger-color-alert;
  font-size: 14px;
  width: 100%;
  font-weight: bold;
  margin-bottom: 15px;
}

.RML-login-modal-indicator {
  text-align: center;
  margin-top: 5px;
  margin-right: 10px;
}

.RML-recover-password-anchor {
  @include transition(all 0.5s ease);

  font-size: 14px;
  color: $color-p1-dark;
  cursor: pointer;
  text-transform: capitalize;
  font-weight: bold;

  &:hover,
  &:active,
  &:focus {
    color: $color-p1-normal-b;
  }
}

.RML-recover-password-success-label {
  font-size: 14px;
  margin-bottom: 15px;
  color: $color-p1-dark;
  display: block;
  font-weight: bold;
}

.RML-login-modal-close {
  border-top-right-radius: 0.1875rem;
  border-top-left-radius: 0.1875rem;
  color: white;
  background-color: #2ca8ff;
  display: flex;
  justify-content: flex-end;
  align-items: center;


  button {
    margin: 1rem 1rem 0 1rem;
    opacity: .5;
    color: #fff;

    @include transition(all 0.5s);

    &:hover {
      opacity: 1;
      color: #fff;
    }
  }


  //.animation(fade-in 2s forwards);
}

.RML-login-modal-mode {
  display: flex;
  justify-content: center;
  border: 0;
  padding: 15px 0.7rem;
  background-color: #2ca8ff;

  color: #fff;

  & > div {
    @include noselect;
    cursor: pointer;
    color: #fff;
    margin: 0 5px 0 0;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 30px;
    font-size: 14px;
    padding: 11px 23px;
    line-height: 1.5;
    @include transition(all 0.3s ease 0s);
    display: block;

    /*display: inline-block;
    width: 50%;
    background-color: transparentize($color-p1-dark, 0.6);
    padding: 12px 0;
    color: $color-p1-darker;
    font-weight: 100;
    font-size: 16px;
    text-align: center;
    border-bottom: 1px solid $color-p1-normal-b;
    cursor: pointer;
    box-sizing: border-box;
    vertical-align: top;*/

    &:hover:not(.disabled) {
      background-color: transparentize($color-p1-dark, 0.8);
    }

    &.active {
      background-color: hsla(0, 0%, 100%, 0.2);
      cursor: default;
    }

    &.disabled {
      cursor: not-allowed;
    }

    //@include animation(fade-in 2s forwards);
  }
}

.RML-social-modal-content-wrap {
  background-color: white;
  max-height: calc(100vh - 79px);
  overflow-y: auto;
  padding: 25px 30px 30px 30px;

  @media (max-width: 420px) {
    padding: 20px 15px 20px 15px;
  }

  //@include animation(fade-in 2s forwards);
}

.RML-login-modal-box-content {
  //@include animation(fade-in 1.2s forwards);
}

.RML-google-login-button {
  @extend .RML-social-login-button;
  background-color: $color-google;

  svg {
    padding: 0px;
  }

  &:hover {
    background-color: transparentize($color-google, 0.2);
  }
}

.RML-social-login-button {
  @extend .RML-btn;
  @include noselect;
  border-radius: 5px;
  width: 100%;
  color: white;
  display: block;
  border: none;
  padding: 8px 10px;
  text-align: left;
  @include transition(background-color 0.5s);

  &:hover,
  &:focus {
    color: white;
  }

  svg {
    width: 22px;
    height: 22px;
    vertical-align: middle;
    background-color: white;
    border-radius: 3px;
    margin-right: 8px;
  }

  &:nth-child(n + 2) {
    margin-top: 5px;
  }
  span {
    vertical-align: middle;
  }
}

.hidden {
  display: none !important;
}

.RML-facebook-login-button {
  @extend .RML-social-login-button;
  background-color: $color-facebook;

  svg {
    //padding: 3px;
    path {
      //fill: $color-facebook;
    }
  }

  &:hover {
    background-color: transparentize($color-facebook, 0.2);
  }
}

.RML-social-methods-separator {
  text-align: center;
  font-size: 16px;
  color: $color-p4-dark;
  margin-top: 12px;
  margin-bottom: 12px;
}

.RML-login-modal-spinner {
  @keyframes rotator {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(270deg);
    }
  }

  animation: rotator 1.4s linear infinite;
  overflow: hidden;

  circle {
    @keyframes dash {
      0% {
        stroke-dashoffset: $offset;
      }
      50% {
        stroke-dashoffset: $offset/4;
        transform: rotate(135deg);
      }
      100% {
        stroke-dashoffset: $offset;
        transform: rotate(450deg);
      }
    }

    @keyframes colors {
      0% {
        stroke: #4285f4;
      }
      25% {
        stroke: #de3e35;
      }
      50% {
        stroke: #f7c223;
      }
      75% {
        stroke: #1b9a59;
      }
      100% {
        stroke: #4285f4;
      }
    }

    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
  }
}

.RML-login-modal-additional-wrap {
  margin-top: 10px;
}

.RML-recover-password-info {
  order: -1;
}

.RML-hidden-socials {
  .RML-facebook-login-button,
  .RML-google-login-button,
  .RML-social-methods-separator,
  {
    display: none
  }

  .RML-login-modal-mode > div:nth-child(1) {
    display: none;
  }
}
