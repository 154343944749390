@import "nowui/now-ui-kit/variables";
@import "nowui/now-ui-kit/mixins";

.media.comment {
  border-radius: 10px;
  background-color: transparent;
  @include transition-on-prop(background-color, 3s, linear);


  &.fresh {
    background-color: $success-color-opacity;
    @include transition-on-prop(background-color, 0.1s, linear);
  }

}
